import styled, { css } from 'styled-components/macro';
import { console0, dinosaur0, stiffy0, stiffy } from '../../styles/colors';

const scaleUpStyle = css`
  transform: scale(1.1);
`;

export const TableCard = styled.div`
  width: 32rem;
  max-width: 80%;
  border: 2px solid ${({ active }) => (active ? dinosaur0 : stiffy)};
  box-shadow: 0px 2px 0px ${({ active }) => (active ? dinosaur0 : stiffy)};

  ${({ scaleUp }) => scaleUp && scaleUpStyle}
`;

export const TableHeader = styled.div`
  height: 7rem;
  display: flex;
  padding: 0 1.5rem;
  color: ${console0};
  align-items: center;
  flex-direction: column;
  background: ${({ active }) => (active ? dinosaur0 : stiffy0)};
  justify-content: center;
`;

export const TableCell = styled.div`
  height: 7rem;
  display: flex;
  padding: 0 1.5rem;
  background: white;
  color: ${console0};
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${({ active }) => (active ? dinosaur0 : stiffy)};
`;
