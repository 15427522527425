import React from 'react';
import { Spacing } from '../styles';
import techrexLogoDark from '../images/techrex-logo-dark.png';

const Header = () => (
  <>
    <img height='25px' src={techrexLogoDark} alt='TechRex Logo' />
    <Spacing height={4} />
    <p>In-person training</p>
  </>
);

export default [
  {
    title: 'Learn to code platforms',
    reasons: [
      'Impersonal video recordings',
      'Impersonal lessons',
      'Template projects'
    ]
  },
  {
    active: true,
    scaleUp: true,
    header: Header,
    reasons: [
      'In-person lessons with an experienced tutor',
      'Personalised lessons',
      'Work on real world projects'
    ]
  },
  {
    title: 'College Programs',
    reasons: [
      'Classroom of 100’s of students',
      'Old Curriculum',
      'Semester projects with little relevance'
    ]
  }
];
