import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import { console0, dinosaur0, dinosaur1 } from '../../styles/colors';

const LinkStyle = css`
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    transition: all 0.2s;
    position: relative;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    color: ${console0};
    text-align: center;
    background-color: ${dinosaur0};
    font-family: 'Work Sans', sans-serif;
    border-bottom: 2px solid ${dinosaur1};
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const InternalLink = styled(Link)`
  ${LinkStyle}
`;

export const ExternalLink = styled.a`
  ${LinkStyle}
`;
