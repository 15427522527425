import React from 'react';
import {
  LinkCard,
  CardImageBackground,
  PaddedContent,
  Sticker
} from './styles';
import { Title, Text } from '../../styles/typography';
import { Spacing } from '../../styles';

const CourseCard = ({
  prefix = '/courses',
  path,
  title,
  overview,
  thumbnail,
  comingSoon,
  popular
}) => (
  <LinkCard to={comingSoon ? null : `${prefix}/${path}`}>
    {comingSoon && <Sticker gem>Coming Soon</Sticker>}
    {popular && <Sticker>Most Popular</Sticker>}
    <CardImageBackground url={thumbnail} />
    <PaddedContent>
      <Title>{title}</Title>
      <Spacing height={8} />
      <Text>{overview}</Text>
    </PaddedContent>
  </LinkCard>
);

export default CourseCard;
