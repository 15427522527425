import { Link } from 'gatsby';
import styled from 'styled-components/macro';
import { phone } from '../../styles/breakpoints';
import { console0, dinosaur0, gemstone0 } from '../../styles/colors';

export const LinkCard = styled(Link)`
  &,
  &:link {
    text-decoration: none;
    background-color: white;
    position: relative;
    cursor: pointer;
    height: 38rem;
    margin: 3rem 2.5rem 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    flex: 0 1 35rem;

    @media screen and (max-width: ${phone}px) {
      flex: 0 0 70%;
      height: auto;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background-color: ${dinosaur0};
    top: 1rem;
    left: 1rem;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const CardImageBackground = styled.div`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 18rem;
`;

export const Sticker = styled.div`
  background-color: ${({ gem }) => (gem ? gemstone0 : dinosaur0)};
  color: ${console0};
  position: absolute;
  z-index: 2;
  padding: 1rem;
`;

export const PaddedContent = styled.div`
  flex: 1;
  padding: 1rem;
  background-color: white;
`;
