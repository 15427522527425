import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import CourseCard from '../components/course-card';
import LinkButton from '../components/link-button';
import eatingDino from '../images/eating-dino.png';
import ComparisonCard from '../components/comparison-card';
import ContactForm from '../components/contact-form';
import { Heading, Highlighted, Spacing, Row } from '../styles';
import {
  HeroWrapper,
  HeroImage,
  HeroTitle,
  CoursesWrapper,
  FlexRow,
  StyledLink,
  WhyTechrexWrapper
} from '../styles/home';

// FIXME: Get rid of these use Netlify CMS
import techrexReasons from '../data/why-techrex';

export default ({
  data: {
    allMarkdownRemark: { nodes: courses }
  }
}) => (
  <Layout>
    <HeroWrapper>
      <HeroImage src={eatingDino} alt='TechRex dino logo in jungle' />
      <Spacing height={4} />
      <Heading>
        <Highlighted>Learn</Highlighted>. <Highlighted>Build</Highlighted>.{' '}
        <Highlighted>Launch</Highlighted>.
      </Heading>
      <Spacing height={4} />
      <HeroTitle align='center'>
        Learn to build websites with a dedicated in-person expert tutor.
      </HeroTitle>
      <Spacing height={40} />
      <Row>
        <LinkButton to='/courses'>View Courses</LinkButton>
        {/* <Spacing width={12} />
        <LinkButton to='/coding-for-high-schools'>High Schools</LinkButton> */}
      </Row>
    </HeroWrapper>
    <CoursesWrapper>
      <Heading align='center'>Our courses</Heading>
      <Spacing height={20} />
      <FlexRow>
        {courses?.map(({ frontmatter: course }) =>
          course.featured ? <CourseCard key={course.title} {...course} /> : null
        )}
        <StyledLink to='/courses'>View all courses</StyledLink>
      </FlexRow>
    </CoursesWrapper>
    <WhyTechrexWrapper>
      <Heading align='center'>Why Techrex?</Heading>
      <Spacing height={40} />
      <Row>
        {techrexReasons.map(({ title, reasons, ...rest }) => (
          <ComparisonCard title={title} points={reasons} {...rest} />
        ))}
      </Row>
    </WhyTechrexWrapper>
    <ContactForm />
  </Layout>
);

export const pageQuery = graphql`
  query FeaturedCourses {
    allMarkdownRemark(sort: { order: ASC, fields: frontmatter___priority }) {
      nodes {
        frontmatter {
          comingSoon
          featured
          overview
          path
          popular
          priority
          title
          thumbnail
        }
      }
    }
  }
`;
