import React from 'react';
import { Title } from '../../styles/typography';
import { TableCard, TableHeader, TableCell } from './styles';

const ComparisonCard = ({ title, header, points, scaleUp, active }) => (
  <TableCard scaleUp={scaleUp} active={active}>
    <TableHeader active={active}>
      {header ? header() : <Title align='center'>{title}</Title>}
    </TableHeader>
    {points.map(point => (
      <TableCell key={point} active={active}>
        {point}
      </TableCell>
    ))}
  </TableCard>
);

export default ComparisonCard;
