import { Link } from 'gatsby';
import { phone } from './breakpoints';
import styled from 'styled-components/macro';
import pattern from '../images/stiffy-pattern.png';
import { ContentWrapper, Subtitle } from './index';
import { stiffy0, stiffy1, console0 } from './colors';

export const HeroWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8rem;
`;

export const HeroImage = styled.img`
  height: 10rem;

  @media screen and (max-width: ${phone}px) {
    height: 6rem;
    margin-bottom: 2rem;
  }
`;

export const HeroTitle = styled(Subtitle)`
  max-width: 60rem;
`;

export const CoursesWrapper = styled.section`
  background-color: ${stiffy0};
  padding: 7rem 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${phone}px) {
    padding: 7rem 1rem;
  }
`;

export const StyledLink = styled(Link)`
  &,
  &:link,
  &:visited,
  &:active,
  &hover {
    font-size: 1.5rem;
    color: ${console0};
    text-align: center;
    margin: 3rem 2.5rem 0;
    width: 35rem;

    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const WhyTechrexWrapper = styled.section`
  background: url(${pattern}), ${stiffy1};
  background-repeat: repeat;
  background-size: 50rem;
  padding: 7rem 6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${phone}px) {
    padding: 7rem 1rem;
  }
`;
